@use "../config" as *;
.waf-listing {
    &.home-photo-listing {
        background: none;
        @include listing-card(overlay);
        @extend .full-width;
        @extend %isolate;
        @extend %py-6;
        @extend %relative;
        &::before {
            content: '';
            width: var(--window-inner-width);
            z-index: map-get($map: $zindex, $key: patterns);
            pointer-events: none;
            @include background(hsl(var(--hsl-neutral-100) / 0.1), "cssimages/pattern/home-listing-bg.svg", top / auto repeat-x);
            @extend %h-100;
            @extend %pos-center;
        }
        .layout-wrapper {
            max-width: unset;
        }
        .waf-head {
            @extend %mb-4;
            @extend %mx-2;
        }
        .title {
            @extend %secondary-1100;
        }
        .article {
            --_fontsize: 14;
            &-list {
                gap: 0;
            }
            &-item {
                border-radius: 0;
                @extend %article-item-scale;
            }
            &-title {
                --_fontsize: 14;
                --_line-height: 2.1;
                @extend %mb-3;
            }
            &-content {
                @extend %d-none;
            }
            &-meta {
                @extend %mb-0;
            }
            &-thumbnail {
                .img-box {
                    border-radius: 0
                }
            }
        }
        .swiper {
            &-button-next {
                &::after {
                    @extend %secondary-1000;
                }
            }
            &-slide {
                transform: scaleY(0.9);
                opacity: .7;
                @include transition(500ms ease-in-out);
                .img-box {
                    border-radius: 0;
                }
            }
            &-slide-active {
                transform: scaleY(1);
                opacity: 1;
                &.article-item {
                    @extend %full-radius;
                }
                .article {
                    &-content {
                        @extend %flex;
                        @extend %pt-0;
                        .meta-category {
                            bottom: calc(100% + .5rem);
                            @extend %absolute;
                            @extend %neutral-1000-bg-5;
                            @extend %primary-500;
                        }
                    }
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        &.home-photo-listing {
            .swiper {
                &-button-next,
                &-button-prev {
                    opacity: 0;
                }
            }
        }
    }
}
@include mq(col-xl) {
    .waf-listing {
        &.home-photo-listing {
            --_swiper-button-height: 2rem;
            padding-block: var(--space-12);
            .waf-head {
                padding-inline: var(--container-white-space);
            }
            .head-tab {
                margin-right: calc(2 * var(--swiper-button-width) + var(--space-1));
            }
            .preview-swiper {
                position: static;
            }
            .article-item {
                width: 26.6rem;
            }
            .swiper {
                &-slide {
                    transform: scaleY(0.9);
                    opacity: .7;
                    @include transition(500ms ease-in-out);
                }
                &-button-next,
                &-button-prev {
                    opacity: 1;
                    top: calc(var(--space-12) + .35rem);
                    height: var(--_swiper-button-height);
                    &::after {
                        font-size: .6rem;
                    }
                }
                &-button-next {
                    right: var(--container-white-space);
                    border-radius: 0 var(--half-radius) var(--half-radius) 0;
                }
                &-button-prev {
                    left: auto;
                    right: calc(var(--container-white-space) + var(--swiper-button-width));
                    border-radius: var(--half-radius) 0 0 var(--half-radius);
                }
                &-slide-active {
                    &.article-item {
                        border-radius: 0;
                    }
                    .article-content {
                        display: none;
                    }
                }
                &-slide-prev {
                    transform: scaleY(1);
                    opacity: 1;
                    &.article-item {
                        border-radius: var(--full-radius);
                    }
                    .article-content {
                        display: flex;
                        padding-top: 0;
                        .meta-category{
                            position: absolute;
                            bottom: calc(100% + 1rem);
                           background: clr(neutral-1000,5);
                           color: clr(primary-500);
                        }
                    }
                }
            }
        }
    }
}