@use './config/' as *;
@forward './main-scss/';
@forward "./listing/home-photo-listing";
@forward "./listing/listing-common";
@forward "./swiper/swiper-bundle";
body {
    &.webview {
        .waf-head {
            padding-top: 0;
            .title {
                @extend %d-block;
            }
        }
    }
}
.footer-top {
    @extend %d-none;
}
.waf-green-initiative-internal {
    @extend %mx-3-neg;
    .waf-banner {
        @extend %px-3;
        @extend %relative;
        h2.title {
            @extend %font-20-pm;
            @extend %neutral-50;
            @extend %pt-4;
            @extend %pb-6;
            @extend %capitalize;
        }
        .banner {
            &-thumbnail {
                aspect-ratio: 16 / 9;
                border-radius: var(--full-radius);
                @extend %w-100;
                @extend %relative;
                @extend %hidden;
                &::after {
                    content: "";
                    background-image: linear-gradient(to top, var(--neutral-900), transparent);
                    z-index: var(--z-overlay);
                    inset: 0;
                    @extend %pointer-none;
                    @extend %absolute;
                }
            }
            &-image {
                object-fit: cover;
                object-position: top center;
                @extend %h-100;
            }
        }
        .thumbnail {
            &-content {
                z-index: var(--z-index2);
                @include position(null, null, 0, 0);
                @extend %neutral-50;
                @extend %w-100;
                @extend %flex;
                @extend %flex-wrap;
                @extend %capitalize;
            }
            &-title {
                margin: 0 auto;
                @extend %font-14-pm;
                @extend %text-center;
                @extend %pb-4;
            }
        }
        &::after {
            content: "";
            top: calc(var(--shape-head, 35rem) - var(--header-height) - 6.2rem);
            height: var(--content-width);
            margin: 0 auto;
            z-index: -1;
            @include position(null, 0, null, 0);
            @extend %w-100;
            @extend %secondary-100-bg;
        }
    }
    .waf-why-we-care {
        @extend %secondary-100-bg;
        @extend %px-3;
        @extend %pt-6;
        .title {
            @extend %secondary-1000;
            @extend %uppercase;
            @extend %pb-4;
        }
        .text {
            line-height: 2.1rem;
            @extend %neutral-800;
            @extend %font-14-pr;
            &:not(:last-child) {
                @extend %pb-4;
            }
        }
    }
    .waf-what-we-do {
        @extend %secondary-100-bg;
        @extend %relative;
        @extend %px-3;
        @extend %py-6;
        .title {
            @extend %secondary-1000;
            @extend %uppercase;
            @extend %pb-4;
        }
        .text {
            line-height: 2.1rem;
            @extend %neutral-800;
            @extend %font-14-pr;
            &:not(:last-child) {
                @extend %pb-4;
            }
        }
        .disc-list {
            list-style-type: disc;
            @extend %pl-3;
            & > li {
                @extend %pb-3;
                @extend %neutral-800;
                @extend %font-14-pr;
                &:last-child {
                    @extend %pb-6;
                }
            }
        }
        &::after {
            content: "";
            background: url("/static-assets/images/patterns/latest-update-bg.png?v=#{$image-version}") bottom / cover repeat-x;
            opacity: 0.3;
            @include position-combo(tl);
            @extend %pointer-none;
            @extend %h-100;
            @extend %w-100;
        }
    }
    .waf-impact-number {
        @extend %relative;
        @extend %px-3;
        @extend %pt-6;
        @extend %neutral-50-bg;
        .title {
            @extend %secondary-1000;
            @extend %uppercase;
            @extend %pb-6;
        }
        .impact {
            &-body {
                @extend %pl-10;
                @extend %mt-3-neg;
                @extend %secondary-1000;
            }
            &-list {
                @extend %gap-8;
                @extend %flex-wrap;
                @extend %flex-column;
            }
            &-heads {
                @extend %gap-2;
                @extend %flex;
            }
            &-item {
                @extend %w-100;
                @extend %relative;
                &:before {
                    content: "";
                    height: 0.1rem;
                    opacity: 30%;
                    bottom: var(--space-4-neg);
                    @extend %w-100;
                    @extend %pos-x-center;
                    @extend %neutral-100-bg;
                }
                &:last-child {
                    margin-bottom: 0;
                    &::before {
                        @extend %d-none;
                    }
                }
                &::after {
                    @extend %d-none;
                }
            }
            &-item.first {
                order: 3;
                .count {
                    @extend %font-20-pm;
                }
                .subtext {
                    margin-top: 0.4rem;
                    line-height: 1.5;
                    @extend %font-12-pr;
                }
                .text {
                    line-height: 1.5;
                    @extend %font-14-pr;
                }
                .impact {
                    &-body {
                        @extend %pl-18;
                        @extend %mt-10-neg;
                    }
                    &-image {
                        aspect-ratio: 1 / 1;
                        width: 8rem;
                        height: 8rem;
                    }
                }
            }
            &-item.second {
                order: 1;
            }
            &-item.third {
                order: 2;
            }
            &-item.fourth {
                order: 4;
            }
            &-item.fifth {
                order: 5;
            }
            &-image {
                aspect-ratio: 1 / 1;
                width: 4rem;
                height: 4rem;
            }
        }
        .count {
            @extend %font-18-pm;
            @extend %secondary-1000;
        }
        .subtext {
            margin-top: 0.4rem;
            @extend %font-12-pr;
            @extend %secondary-1000;
        }
        .text {
            word-break: break-word;
            @extend %font-12-pr;
            @extend %neutral-800;
            @extend %d-block;
        }
        .records {
            @extend %py-6;
            @extend %font-14-pr;
            @extend %neutral-800;
        }
        &::after {
            content: "";
            background: url("/static-assets/images/green-intiative/internal-page/impact-bg.png?v=#{$image-version}") left/auto repeat-y;
            background-size: contain;
            @include position-combo(tl);
            @extend %pointer-none;
            @extend %h-100;
            @extend %w-100;
        }
    }
    .waf-stories {
        background: linear-gradient(0deg, var(--secondary-1000) 25.82%, var(--secondary-900) 113.23%);
        @extend %pt-6;
        @extend %pb-8;
        @extend %relative;
        &::after {
            content: "";
            background: url("/static-assets/images/green-intiative/pattern.png?v=#{$image-version}") left/auto repeat-y;
            background-size: contain;
            @include position-combo(tl);
            @extend %pointer-none;
            @extend %h-100;
            @extend %w-100;
        }
        .waf-head {
            @extend %px-3;
            @extend %pb-6;
        }
        .title {
            @extend %neutral-50;
            @extend %uppercase;
        }
        .swiper {
            @extend %relative;
            @extend %w-100;
            @extend %hidden;
            @extend %pb-9;
            &-pagination {
                height: 0.4rem;
                bottom: var(--space-1);
                @extend %flex-c-n;
                @extend %gap-1;
                @extend %w-100;
                &.swiper-pagination-bullets .swiper-pagination-bullet {
                    margin-inline: 0;
                }
                &-bullet {
                    width: 1.6rem;
                    transition: width 0.3s ease-in, background-color 0.3s ease-in;
                    @extend %h-100;
                    @extend %rounded-radius;
                    @extend %neutral-50-bg-4;
                    &-active {
                        width: 3.2rem;
                        background-color: var(--primary-500);
                    }
                }
            }
            &-slide {
                width: 78%;
                opacity: 0.6;
                transform: scale(0.9);
                transition: all 0.3s ease-in-out;
                @extend %full-radius;
                @extend %hidden;
                @extend %relative;
            }
            &-button {
                &-next,
                &-prev {
                    width: 2.8rem;
                    top: calc(var(--swiper-navigation-top-offset, 50%) - var(--space-6));
                    &:after {
                        font-size: 0.7rem;
                    }
                }
                &-next {
                    right: 0.6rem;
                }
                &-prev {
                    left: 0.6rem;
                }
            }
            &-slide.swiper-slide-active {
                transform: scale(1);
                opacity: 1;
            }
            .title {
                @extend %px-0;
                @extend %pb-0;
                @extend %relative;
                @extend %font-16-pm;
                @extend %capitalize;
            }
        }
        &.photo-stories {
            --_card-thumbnail-size: 9rem;
            .swiper {
                &-slide {
                    height: unset;
                    @extend %neutral-50-bg;
                    @extend %p-4;
                    &::before {
                        content: "";
                        width: 4.3rem;
                        height: 4.8rem;
                        @include background(null, "/green-intiative/internal-page/stories-quotes.svg", center/cover no-repeat);
                        @include position(0, 2rem);
                        @extend %pointer-none;
                        @extend %d-block;
                    }
                }
                .title {
                    @extend %secondary-1000;
                }
            }
            .card {
                &-thumbnail {
                    aspect-ratio: 1 / 1;
                    width: var(--_card-thumbnail-size);
                    height: var(--_card-thumbnail-size);
                }
                &-head {
                    width: calc(100% - var(--_card-thumbnail-size));
                    align-self: center;
                    @extend %pl-3;
                }
                &-wrap {
                    flex-wrap: wrap;
                    @extend %h-100;
                    @extend %flex;
                }
                &-body {
                    flex-basis: 100%;
                }
                &-description {
                    border-top: .1rem solid clr(neutral-100, 2);
                    @include truncate(15, 12);
                    height: auto;
                    @extend %mt-3;
                    @extend %pt-3;
                    @extend %relative;
                    .text {
                        line-height: 1.6;
                        @extend %font-12-pr;
                        @extend %mb-2;
                        @extend %neutral-500;
                    }
                }
                &-footer {
                    flex-basis: 100%;
                    @extend %mt-auto;
                }
            }
            .stories-image {
                @extend %w-100;
                @extend %h-100;
            }
            .read-more {
                width: max-content;
                @extend %half-radius;
                @extend %py-2;
                @extend %px-4;
                @extend %mt-3;
                @extend %secondary-1000;
                @extend %primary-500-bg;
                @extend %d-block;
            }
            .btn-text {
                @extend %font-12-pm;
                @extend %uppercase;
            }
            .img-box {
                @extend %full-radius;
            }
        }
        &.video-stories {
            .swiper {
                .title {
                    @extend %neutral-50;
                }
            }
            .card {
                &-wrap {
                    @extend %flex-column-n-c;
                }
                &-video {
                    @extend %full-radius;
                }
                &-head {
                    @extend %pb-2;
                }
            }
        }
    }
    .waf-inspire {
        @extend %primary-50-bg;
        @extend %pt-6;
        @extend %px-3;
        .waf-body {
            @extend %py-6;
        }
        .view-all {
            @extend %font-12;
            @extend %half-radius;
        }
        .item-type-icon {
            z-index: var(--z-default);
            box-shadow: 0 0 0 0.8rem clr(neutral-50, 2), 0 0 0 1.5rem clr(neutral-50, 2);
            @include position-combo(center);
            @extend %rounded-radius;
            @extend %flex;
            @extend %neutral-50-bg-3;
            @extend %font-0;
            &::before {
                font-size: 1.6rem;
                @extend %primary-500;
            }
        }
        .title {
            max-width: 13rem;
        }
        .reaction-section {
            @extend %d-none;
        }
        .article {
            &-item {
                @extend %full-radius;
                @extend %hidden;
                &:nth-child(2) {
                    @extend %mt-3;
                }
            }
            &-thumbnail {
                aspect-ratio: 16 / 9;
                @include overlay();
            }
            &-wrap {
                @extend %relative;
            }
            &-content {
                bottom: 0;
                z-index: var(--z-default);
                @extend %w-100;
                @extend %absolute;
                @extend %px-3;
            }
            &-meta {
                @extend %d-none;
            }
            &-title {
                font-weight: 500;
                @extend %primary-50;
                @extend %text-center;
                @include truncate(var(--_line, 2), var(--_fontsize, 14), var(--_line-height, 1.2));
            }
        }
        .inspire-image {
            transition: 1s ease;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    &.waf-run-root {
        .waf-banner {
            .banner-thumbnail {
                &::after {
                    content: unset;
                }
            }
        }
        .waf-impact-number {
            .dash-list {
                list-style-type: none;
                @extend %pt-4;
                & > li {
                    text-indent: -1rem;
                    @extend %pb-3;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
                & > li:before {
                    content: "-";
                    @extend %pr-1;
                }
            }
            .impact {
                &-body {
                    padding-left: 0;
                    @extend %mt-2-neg;
                }
                &-image {
                    width: 5.8rem;
                    height: 5.8rem;
                    aspect-ratio: 1/1;
                }
                &-list {
                    @extend %pb-6;
                }
            }
            .subtext {
                padding-left: 0;
                @extend %neutral-800;
            }
            .records {
                @extend %d-none;
            }
            .count {
                @extend %font-24-pm;
            }
        }
    }
    &.waf-play-forward {
        .waf-banner {
            .banner-thumbnail {
                &::after {
                    content: "";
                }
            }
        }
        .waf-impact-number {
            .count {
                @extend %mt-1;
                @extend %font-32-pm;
            }
            .subtext {
                @extend %mt-1;
                @extend %ml-8-neg;
                @extend %d-block;
            }
            .impact {
                &-image {
                    width: 3rem;
                    height: 4rem;
                }
                &-item {
                    &:last-child {
                        padding-bottom: unset;
                    }
                }
                &-body {
                    margin-top: 0;
                }
                &-list {
                    @extend %pb-4;
                }
            }
            .dash-list {
                border-top: 0.1rem solid clr(neutral-100);
                @extend %pt-4;
                @extend %pb-6;
            }
        }
    }
    &.waf-skill-up {
        .waf-banner {
            .banner-thumbnail {
                &::after {
                    content: "";
                }
            }
        }
        .waf-impact-number {
            .impact-image {
                aspect-ratio: 1 / 1;
                width: 4rem;
                height: 4rem;
            }
            .head-content {
                .count {
                    @extend %mt-2;
                    @extend %font-20-pm;
                }
            }
            .subtext {
                padding-left: 3.9rem;
                @extend %mt-2;
                @extend %ml-18-neg;
            }
            .waf-body {
                .impact-list {
                    @extend %pb-4;
                }
            }
        }
        .waf-acid-attack-survivors {
            @extend %px-3;
            @extend %pt-6;
            @extend %pb-2;
            @extend %neutral-50-bg;
            .title {
                @extend %secondary-1000;
                @extend %uppercase;
                @extend %pb-4;
            }
            .survivors {
                &-title {
                    border-radius: var(--half-radius);
                    @extend %secondary-100-bg;
                    .title {
                        @extend %font-20-pm;
                        @extend %capitalize;
                        @extend %px-4;
                        @extend %py-6;
                    }
                }
                &-thumbnail {
                    aspect-ratio: 16 / 9;
                    border-radius: 0.5rem 0.5rem 0 0;
                    @extend %hidden;
                }
                &-image {
                    object-fit: cover;
                    object-position: top center;
                }
            }
            .attack-survivors {
                @extend %neutral-50-bg;
                .waf-why-we-care,
                .waf-what-we-do {
                    padding-inline: 0;
                    @extend %neutral-50-bg;
                    @extend %pb-4;
                    .layout-wrapper {
                        padding-inline: 0;
                    }
                }
                .waf-what-we-do {
                    padding-top: 0;
                }
            }
        }
        .waf-impact-number.attack-survivors {
            @extend %secondary-100-bg;
            .count {
                margin-top: 0;
            }
            .impact-list {
                @extend %pb-4;
            }
        }
    }
}
.waf-breadcrumb .breadcrumb-item {
    @extend %capitalize;
}
.waf-listing.home-photo-listing {
    padding-block: 0;
    padding-top: var(--space-8);
    .waf-head {
        padding-top: 0;
    }
    .swiper {
        padding-bottom: var(--space-8);
    }
}
@include mq(col-md) {
    .waf-green-initiative-internal {
        .waf-banner {
            h2.title {
                font-size: 3.2rem;
                padding-bottom: var(--space-8);
            }
            .thumbnail-title {
                font-size: 2rem;
            }
        }
        .waf-why-we-care {
            .title {
                font-size: 3.2rem;
            }
        }
        .waf-what-we-do {
            .title {
                font-size: 3.2rem;
            }
            .text {
                padding-bottom: var(--space-8);
            }
            .disc-list > li {
                &:last-child {
                    padding-bottom: var(--space-8);
                }
            }
        }
        .waf-impact-number {
            .title {
                font-size: 3.2rem;
            }
            .count {
                font-size: 5.2rem;
            }
            .subtext,
            .text {
                font-size: 1.4rem;
            }
            .records {
                padding-block: var(--space-8);
            }
            .impact {
                &-list {
                    flex-direction: row;
                    justify-content: center;
                }
                &-item {
                    width: calc(50% - var(--space-10));
                    &::after {
                        content: "";
                        height: 5rem;
                        width: 0.1rem;
                        margin-top: var(--space-2);
                        background-color: var(--neutral-100);
                        @include position(0, -2rem);
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    &::before {
                        display: none;
                    }
                    &:nth-child(2n) {
                        &::after {
                            display: none;
                        }
                    }
                    &:nth-child(3n) {
                        &::after {
                            display: block;
                        }
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                    &.first {
                        order: 1;
                        .count {
                            font-size: 5.2rem;
                        }
                        .subtext {
                            margin-top: unset;
                        }
                        .subtext,
                        .text {
                            font-size: 1.4rem;
                        }
                        .impact-image {
                            width: 4rem;
                            height: 4rem;
                        }
                        .impact-body {
                            padding-left: var(--space-12);
                            margin-top: 0;
                        }
                    }
                    &.second {
                        order: 2;
                    }
                    &.third {
                        order: 3;
                    }
                    &.fourth {
                        order: 4;
                    }
                    &.fifth {
                        order: 5;
                    }
                    &::after {
                        display: block;
                    }
                }
                &-content {
                    position: relative;
                }
                &-body {
                    padding-left: var(--space-12);
                    margin-top: 0;
                }
                &-heads {
                    align-items: center;
                    gap: var(--space-3);
                }
            }
            .subtext {
                margin-top: unset;
            }
        }
        .waf-stories {
            padding-bottom: var(--space-8);
            .waf-head {
                padding-inline: unset;
            }
            .waf-body {
                position: relative;
                left: calc(-1* var(--container-white-space));
                width: var(--window-inner-width);
            }
            .title {
                padding-inline: 0;
                font-size: 3.2rem;
            }
            .swiper {
                position: static;
                &-slide {
                    transform: scale(0.95);
                }
                &-button {
                    &-next {
                        right: var(--container-white-space);
                        border-radius: 0 0.5rem 0.5rem 0;
                        &.swiper-button-disabled {
                            opacity: unset;
                            background: var(--primary-200);
                        }
                        &.swiper-button-disabled::after {
                            color: var(--secondary-800);
                            font-size: 1rem;
                        }
                        &::after {
                            font-size: 1rem;
                        }
                    }
                    &-prev {
                        left: auto;
                        right: calc(var(--container-white-space) + var(--swiper-button-width));
                        border-radius: 0.5rem 0 0 0.5rem;
                        &.swiper-button-disabled {
                            opacity: unset;
                            background: var(--primary-200);
                        }
                        &.swiper-button-disabled::after {
                            color: var(--secondary-800);
                        }
                        &::after {
                            font-size: 1rem;
                        }
                    }
                    &-prev,
                    &-next {
                        display: flex;
                        width: 3.9rem;
                        height: 3.5rem;
                        @include position(calc(var(--space-6) - 9.7rem), null, null, null);
                    }
                }
            }
            &.photo-stories {
                .swiper {
                    &-slide {
                        padding: var(--space-6);
                        width: 100%;
                        &::before {
                            right: 3rem;
                        }
                    }
                }
                .card {
                    &-description {
                        @include truncate(10, 12);
                        height: auto;
                        .text {
                            font-size: 1.6rem;
                        }
                    }
                }
                .btn-text {
                    font-size: 1.4rem;
                }
            }
        }
        .waf-inspire {
            padding: var(--space-8) var(--container-white-space);
            .waf-body {
                padding-bottom: var(--space-0);
            }
            .title {
                font-size: 3.2rem;
                max-width: 100%;
            }
            .view-all {
                font-size: 1.4rem;
                padding: var(--space-2) var(--space-3);
            }
            .article {
                &-list {
                    gap: var(--space-3);
                    flex-wrap: wrap;
                    @include flex-config(flex, null, center, center);
                }
                &-title {
                    @include truncate(var(--_line, 2), var(--_fontsize, 16), var(--_line-height, 1.6));
                }
                &-item {
                    width: calc(50% - var(--space-3) / 2);
                    &:nth-child(2) {
                        margin: 0;
                    }
                }
                &-content {
                    padding-inline: var(--space-4);
                }
            }
        }
        &.waf-run-root {
            .waf-impact-number {
                .impact {
                    &-item {
                        &:nth-child(2n) {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
                .dash-list {
                    padding-top: var(--space-4);
                }
                .subtext {
                    padding-left: 0;
                    color: var(--secondary-1000);
                }
                .impact {
                    &-list {
                        justify-content: flex-start;
                        padding-bottom: var(--space-12);
                    }
                    &-item {
                        width: calc(50% - var(--space-10));
                    }
                }
                .head-content {
                    display: block;
                }
                .count {
                    font-size: 5.2rem;
                }
            }
        }
        &.waf-play-forward {
            .waf-impact-number {
                .impact {
                    &-image {
                        width: 4rem;
                        height: 5.5rem;
                        aspect-ratio: unset;
                    }
                    &-list {
                        padding-bottom: var(--space-4);
                    }
                }
                .subtext {
                    margin-top: unset;
                    margin-left: unset;
                    display: unset;
                }
                .dash-list {
                    padding-bottom: var(--space-12);
                }
            }
        }
        &.waf-skill-up {
            .waf-impact-number {
                .impact-wrap {
                    .impact-heads {
                        justify-content: flex-start;
                    }
                    .head-content {
                        display: block;
                    }
                    .subtext {
                        margin-left: -1.3rem;
                    }
                }
            }
            .waf-acid-attack-survivors {
                padding-top: var(--space-12);
                padding-bottom: var(--space-8);
                .title {
                    padding-bottom: var(--space-8);
                }
                .survivors {
                    &-wrap {
                        display: flex;
                    }
                    &-thumbnail {
                        width: 50%;
                        border-radius: 0.5rem 0 0 0.5rem;
                    }
                    &-title {
                        width: 50%;
                        .title {
                            position: relative;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                .attack-survivors {
                    display: flex;
                    gap: var(--space-4);
                    .waf-why-we-care,
                    .waf-what-we-do {
                        padding-top: var(--space-8);
                    }
                }
            }
        }
    }
    .waf-listing.home-photo-listing {
        padding-block: 0;
        padding-top: var(--space-8);
        .waf-head {
            margin-inline: var(--space-0);
            .head-tab {
                margin-right: calc(2* var(--swiper-button-width) + var(--space-8));
                li {
                    a {
                        font-size: 1.4rem;
                        padding: var(--space-2) var(--space-3);
                    }
                }
            }
        }
        .title {
            font-size: 3.2rem;
        }
        .swiper {
            &-button-next,
            &-button-prev {
                height: unset;
                top: calc(var(--space-8) + .18rem);
                opacity: 1;
                width: 3.9rem;
                height: 3.4rem;
                &::after {
                    font-size: 1rem;
                }
            }
            &-button-next {
                right: var(--container-white-space);
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
            }
            &-button-prev {
                left: auto;
                right: calc(var(--container-white-space) + var(--swiper-button-width));
                border-radius: var(--half-radius) 0 0 var(--half-radius);
            }
        }
    }
}
@include mq(col-lg) {
    .waf-green-initiative-internal {
        .waf-banner {
            .waf-body {
                padding-inline: var(--space-18);
            }
        }
        .waf-why-we-care {
            padding-top: var(--space-12);
            padding-inline: 0;
            .layout-wrapper {
                padding-inline: var(--space-18);
            }
        }
        .waf-what-we-do {
            padding-block: var(--space-12);
            .layout-wrapper {
                padding-inline: var(--space-18);
            }
        }
        .waf-impact-number {
            padding-top: var(--space-12);
            .title {
                padding-bottom: var(--space-12);
            }
            .impact-item {
                width: calc(33.3% - var(--space-10));
                &:nth-child(2n) {
                    &::after {
                        display: unset;
                    }
                }
                &:nth-child(3n) {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .waf-stories {
            .swiper-slide {
                transform: scale(0.95);
                &::after {
                    margin-top: var(--space-4-neg);
                    right: 4rem;
                }
            }
            .swiper-button {
                &-prev {
                    left: auto;
                    right: calc(var(--container-white-space) + var(--swiper-button-width));
                }
                &-next {
                    right: var(--container-white-space);
                }
            }
            &.photo-stories {
                --_card-thumbnail-size: 27rem;
                .swiper {
                    .title {
                        font-size: 2.4rem;
                    }
                }
                .card {
                    &-wrap {
                        display: grid;
                        grid-template-columns: var(--_card-thumbnail-size) 1fr;
                        grid-column-gap: 3rem;
                    }
                    &-thumbnail {
                        grid-area: 1 / 1 / 4 / 2;
                    }
                    &-head {
                        width: 100%;
                        padding-left: 0;
                        grid-area: 1 / 2 / 2 / 3;
                    }
                    &-body {
                        grid-area: 2 / 2 / 3 / 3;
                    }
                    &-footer {
                        grid-area: 3 / 2 / 4 / 3;
                    }
                    &-thumbnail {
                        width: var(--_card-thumbnail-size);
                        height: var(--_card-thumbnail-size);
                    }
                    &-description {
                        margin-top: var(--space-4);
                        padding-top: var(--space-4);
                    }
                }
            }
            &.video-stories {
                .swiper {
                    .title {
                        font-size: 2rem;
                    }
                }
            }
        }
        &.waf-run-root {
            .waf-impact-number {
                .head-content {
                    @include flex-config(flex, null, null, center);
                }
                .subtext {
                    line-height: 2.1rem;
                    padding-left: var(--space-3);
                }
            }
        }
    }
}